import * as React from "react"
import Layout from "../views/layouts/layout"
import PostDefault from "../views/templates/template.post.default"
import {graphql} from "gatsby";


export default function PostController(props) {
    const post = props.data.post
    return (
        <Layout node={post}>
            {setTemplate(post)}
        </Layout>
    )
}

const setTemplate = (post) => {
    switch(post.template.templateName) {
        default:
            return <PostDefault post={post}/>
    }
}

export const postQuery = (graphql`
  query PostById(
    $id: String!
  ) {  
    post: wpPost(id: { eq: $id }) {
      id
      title
      template {
        templateName
      }
      language{
        code
      }
      
        uri
      contentTypeName
      seo {
        canonical
        metaDescription
        metaTitle
        nofollow
        noindex
        h1
      }
      ...PostDefaultInformation
    }
  }
`)