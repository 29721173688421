import * as React from "react"
import {graphql} from "gatsby";
import {Box, Breadcrumb, BreadcrumbItem, BreadcrumbLink, Heading} from "@chakra-ui/react"
import HeroPost from "../components/heroPost/hero";
import Stripes from "../components/common/stripes";
import Letter from "../components/common/letter";
import FeaturedImage from "../components/common/featuredImage";

export default function html(props) {
	const post = props.post
	const page = props.page

	let cat = (post.categories && post.categories.nodes) ? post.categories.nodes[0].name : '';
	let author = (post.author && post.author.node) ? post.author.node.name : '';

	return (
		<>
			<HeroPost page={page}/>
			<Box p={{ base: '100px 5% 20px 5%', md:'100px 10% 40px 10%',lg:'130px 25% 50px 25%'}} position={'relative'}>
				<Letter letter={'P'} top={'15%'} left={'-6%'} fontSize={'464px'}/>
				<Stripes bg2={'#f4f4f4'} z2={'-1'} z0={'-1'} />
				<Box mt={'30px'}  fontSize={'11px'} color={'#32353833'} fontWeight={'semibold'} zIndex={'2'}>
					<Breadcrumb>
						<BreadcrumbItem>
							<BreadcrumbLink href={'/'}>Home</BreadcrumbLink>
						</BreadcrumbItem>
						<BreadcrumbItem key='1'>
							<BreadcrumbLink href={'/blog/'}>Blog</BreadcrumbLink>
						</BreadcrumbItem>
						<BreadcrumbItem isCurrentPage>
							<BreadcrumbLink href='#'>{post.title}</BreadcrumbLink>
						</BreadcrumbItem>
					</Breadcrumb>
				</Box>
				<Box mt={'54px'} mb={'24px'} color={'#32353833'} fontSize={'14px'} fontWeight={'semibold'} position={'relative'} display={'flex'} justifyContent={'space-between'}>
					<Box>{post.dateGmt} | {post.date} | {author}</Box>
					<Box color = {'#9BCD66'} textTransform={'uppercase'}>{cat}</Box>
				</Box>
				<Heading as="h1" mb={'40px'} fontSize={'42px'} lineHeight={'48px'} fontFamily="'essonnes-headline', serif" fontWeight={'bold'}>{post.title}</Heading>
				<FeaturedImage post={post} />
				<Box sx={{
					'p' : { fontSize: '14px', lineHeight: '28px', mb:'28px'},
					'h2' : { fontWeight: 'bold', fontSize:'15px', lineHeight: '28px', mt: '30px', mb: '30px' },
					'ul' : { listStyleType: "none",  ml:'2em'},
					'li' : {fontWeight: 'normal', display: 'block'},
					'li:before' : {	content: '"■"',
						color: '#9BCD66',
						fontWeight: 'bold',
						display: 'inline-block',
						w: '1em',
						ml: '-2em',
						pr: '2em'},
				}}>
					<div dangerouslySetInnerHTML={{ __html: post.content}}></div>
				</Box>

			</Box>
		</>
	)
}

export const query = graphql`
  fragment PostDefaultInformation on WpPost {
	  	  title
		  excerpt
	      content
		  slug
		  uri
		  language {
			  code
		  }
		  date(fromNow: true, locale: "pl")
		  dateGmt(formatString: "DD MMMM YYYY", locale: "pl")
		  featuredImage {
			  node {
				  sizes
				  filename
				  publicUrl
				  localFile {
					  relativePath
					  childImageSharp {
						  id
						  gatsbyImageData(
							  placeholder: NONE
							  quality: 70
							  formats: [AUTO, WEBP, AVIF]
							  layout: FULL_WIDTH
						  )
					  }
				  }
			  }
		  }
		  author {
			  node {
				  name
			  }
		  }
		  categories {
			  nodes {
				  uri
				  name
			  }
		  }
	 
  }
`