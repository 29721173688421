import * as React from "react"
import {Box} from "@chakra-ui/react";
import Stripes from "../common/stripes";

export default function hero(props) {

    return (
        <Box position='relative' id="top" >
            <Stripes bg1={'#f4f4f4'} bg2={'#f4f4f4'} bg3={'#f4f4f4'} />
        </Box>
    )
}
